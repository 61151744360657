import axios from "axios";
import {getCookie, removeCookie, setCookie} from "typescript-cookie";
import jwtDecode from "jwt-decode";

const access_token = getCookie('goldMark_user_access_token')
const refresh_token = getCookie('goldMark_user_refresh_token')


export const API_URL = 'https://api.goldmark.kg/api'

export const $axios = axios.create({
    withCredentials: false,
    baseURL: API_URL
})

export const LogoutUser = () =>{
    removeCookie('goldMark_user_access_token')
    removeCookie('goldMark_user_refresh_token')
    setTimeout(()=>{
        window.location.reload()
    }, 1000)
}

$axios.interceptors.request.use((config) => {
    if (typeof access_token !== 'undefined' && typeof refresh_token !== 'undefined' ){
        config.headers.Authorization = `JWT ${access_token}`
        if(config.url?.includes('/register/') || config.url?.includes("/personal/info/update/")){
            config.headers["Content-Type"] = 'multipart/form-data'
        }
        else{
            config.headers["Content-Type"] = 'application/json'
        }
        return config
    }
    else{
        return config
    }

}, (error)=>{
    return error
})

$axios.interceptors.response.use((config) => {
    return config
}, async (error)=>{
    const originalRequest = error.config
    if(error.response.status === 401 && !error.config.url?.includes('/auth/token/') && error.config && !error.config._isRetry){
        originalRequest._isRetry = true
        if(typeof access_token !== 'undefined' && typeof refresh_token !== 'undefined'){
            try {
                const response = await axios.post(`${API_URL}/auth/token/refresh/`, JSON.stringify({refresh: refresh_token}),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                // Get the current time in seconds
                const currentTimeInSeconds = Math.floor(Date.now() / 1000);

                const accessDecode: any = jwtDecode(response.data.access)

                const accessExpirationInSeconds = accessDecode.exp;

                // Calculate the difference in seconds
                const accessDifferenceInSeconds = accessExpirationInSeconds - currentTimeInSeconds;

                // Convert the difference in seconds to days
                const accessDifferenceInDays = Math.ceil(accessDifferenceInSeconds / (60 * 60 * 24));

                setCookie('goldMark_user_access_token', response.data.access, {expires: accessDifferenceInDays})
                setTimeout(()=>{
                    window.location.reload()
                },1000)
                return $axios.request(originalRequest)
            }
            catch (e){
                LogoutUser()
            }
        }
        else{
            LogoutUser()
        }
        throw error;
    }
    else{
        return Promise.reject(error)
    }
})

