import url from "../helpers/Url";
import {$axios} from "../http";

async function PostUserAuthData(email: string, password: string) {
    return await $axios.post(url('auth_token_create'),{email: email, password: password})
}
async function GetOtp(email: string) {
    return await $axios.post(url('otp'),{email: email})
}
async function CreateAccessToken(token: string) {
    return await $axios.post(url('token_refresh'),{refresh: token})
}
async function PostUserRegisterData(
    first_name: string,
    last_name: string,
    phone: string,
    email: string,
    password: string,
    confirm_password: string,
    otp: string,
    frontSideImg: File | null | undefined,
    backSideImg: File | null | undefined,
    date_of_birth: string,
) {

    let form_data = new FormData()
    const data: any = {
        passport_front_side: frontSideImg,
        passport_back_side: backSideImg,
        phone: phone,
        first_name: first_name,
        last_name: last_name,
        email: email,
        password: password,
        confirm_password: confirm_password,
        otp: otp,
        date_of_birth: date_of_birth,
    }
    if(data.passport_back_side.name === ''){
        delete data.passport_back_side
    }
    if(data.passport_front_side.name === ''){
        delete data.passport_front_side
    }
    for ( let key in data ) {
        form_data.append(key, data[key]);
    }
    return await $axios.post(url('register'), form_data)
}
async function PasswordRecovery(email: string, otp: string) {
    return await $axios.post(url('recovery'),{
            email: email,
            otp: otp,
        })
}
async function DeleteSelectedOrders(arrOfId: []) {
    return await $axios.post(url('orders_bulk_delete'),{order_ids: arrOfId})
}
async function DeleteSelectedNotifications(arrOfId: []) {
    return await $axios.post(url('notifications_bulk_delete'),{
            notification_ids: arrOfId,
            flag: 'delete',
        })
}
async function CreateOrder(tracking_number: string,title:string, quantity: number ) {
    return await $axios.post(url('orders'),{
            tracking_number: tracking_number,
            title: title,
            quantity: quantity,
    })
}
async function PasswordChange(old_password: string, password: string, confirm_password: string) {
    return await $axios.post(url('passwordChange'),{
            old_password: old_password,
            password: password,
            confirm_password: confirm_password,
        })
}
export {CreateAccessToken, PasswordChange, DeleteSelectedNotifications, CreateOrder, DeleteSelectedOrders, PostUserAuthData, GetOtp, PostUserRegisterData, PasswordRecovery}