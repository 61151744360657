import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {CustomInput, RollBackIcon} from "../../../helpers/Common";
import {GetOtp, PasswordRecovery} from "../../../requests/post";
import {MyTimer} from "../../../helpers/helpers";

const RecoverModal = ({setAuthSteps}: {setAuthSteps: Dispatch<SetStateAction<string>>}) => {
    const [recoverValidation, setRecoverValidation] = useState<any>({
        emailCheck: false,
        emailHelperText: '',
        otpCheck: false,
        otpHelperText: '',
        recoverRequestIsSent: false,
        sendCode: true,
    })
    const [recoverValues, setRecoverValues] = useState<any>({
        email: '',
        otp: '',
    })
    const handleSubmit = (event: React.FormEvent) =>{
        event.preventDefault()
        setRecoverValidation({
            ...recoverValidation,
            recoverRequestIsSent: true,
        })
        PasswordRecovery(recoverValues.email, recoverValues.otp).then(()=>{
            window.location.reload()
        }).catch((err)=>{
            if(err.response.data.user){
                setRecoverValidation({
                    ...recoverValidation,
                    emailCheck: true,
                    emailHelperText: err.response.data.user[0],
                    otpCheck: true,
                    otpHelperText: err.response.data.user[0],
                    recoverRequestIsSent: false,
                })
            }
            if(err.response.data.otp){
                setRecoverValidation({
                    ...recoverValidation,
                    otpCheck: true,
                    otpHelperText: err.response.data.otp[0],
                    recoverRequestIsSent: false,
                })
            }
        })
    }
    const time = new Date();
    const currentTimeLeftForCode = MyTimer(time)
    const handleResendCode = () =>{
        setRecoverValidation({
            ...recoverValidation,
            sendCode: true,
            otpHelperText: 'Код отправлен вам на почту'
        })
        const time = new Date();
        time.setSeconds(time.getSeconds() + 59);
        currentTimeLeftForCode.restart(time)
        GetOtp(recoverValues.email).then(()=>{
        }).catch(()=>{
            setRecoverValidation({
                ...recoverValidation,
                sendCode: false,
                otpHelperText: 'Ошибка при отправке кода',
                otpCheck: true,
            })
        })
    }
    useEffect(()=>{
        if(currentTimeLeftForCode.seconds === 0){
            setRecoverValidation({...recoverValidation, sendCode: false})
        }
    }, [currentTimeLeftForCode.seconds])
    return (
        <div className='authBackground  flex justify-end pr-20 items-center w-full h-full'>
            <form onSubmit={handleSubmit} className='z-20 flex flex-col justify-start items-center py-60px px-50px bg-light-blue rounded-10px shadow-default max-w-auth-width w-full relative'>
                <div className='roll-back_button' onClick={()=>{setAuthSteps('auth')}}>
                    <RollBackIcon/>
                </div>
                <h1 className="auth__title">Восстановление пароля</h1>
                <CustomInput
                    className='text-input'
                    type='email'
                    placeholder='Email'
                    required={true}
                    label=''
                    value={recoverValues.email}
                    onChange={(event)=>{setRecoverValues({
                        ...recoverValues,
                        email: event.target.value,
                    })}}
                    helperText={recoverValidation.emailHelperText}
                    error={recoverValidation.emailCheck}
                />
                <CustomInput
                    className='text-input otp-input'
                    type='text'
                    placeholder='Код'
                    required={true}
                    label=''
                    value={recoverValues.otp}
                    onChange={(event)=>{setRecoverValues({
                        ...recoverValues,
                        otp: event.target.value,
                    })}}
                    helperText={recoverValidation.otpHelperText}
                    error={recoverValidation.otpCheck}
                    inputProps={
                        <div className='otp_icon_button'>
                            {!recoverValidation.sendCode
                                ? <p onClick={()=>handleResendCode()}>Отправить код</p>
                                : <p>0:{currentTimeLeftForCode.seconds}</p>
                            }
                        </div>
                    }
                />

                <button disabled={recoverValidation.recoverRequestIsSent} type='submit' className='submit-button_blue h-12 w-full mb-4 mt-4'>
                    Восстановить
                </button>
                <div className='w-full flex justify-center items-center'>
                    <span className='register__label text-gray mr-1.5'>У вас нет аккаунта?</span>
                    <span className='register__text text-dark-blue' onClick={()=>{setAuthSteps('register')}}>Зарегистрируйтесь</span>
                </div>
            </form>
        </div>
    );
}

export default RecoverModal;
