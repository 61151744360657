import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {GetNotification} from "../../requests/get";
import {RollBackIcon} from "../../helpers/Common";
import moment from "moment";
import parse from 'html-react-parser'

function NotificationViewM() {
    const navigate = useNavigate()
    const {id} = useParams()
    const notification = GetNotification(id)
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>Просмотр уведомления</h1>
                </div>
                {notification.loading
                    ?
                    <div className='w-full flex justify-center items-center'>
                        <div className="loader"></div>
                    </div>
                    :
                    <div className='notification-view__container w-full flex flex-col justify-start items-center'>
                        <div className='notification-view__header w-full px-3 py-3 flex justify-between items-center'>
                            <div className='flex justify-start items-center gap-8'>
                                <div className='notification-view__rollBack-icon' onClick={()=>{navigate('/notifications')}}>
                                    <RollBackIcon/>
                                </div>
                            </div>
                            <div className='flex justify-end items-center gap-8'>
                                <span>
                                    {moment(notification.result?.data.notification.created_at).format('hh:mm DD.MM.YYYY').toString()}
                                </span>
                            </div>
                        </div>
                        <div className='notification-view__message w-full flex flex-col justify-start items-start px-3 py-3'>
                            <h1 className='mb-3'>{notification.result?.data.notification.title}</h1>
                            {parse(notification.result?.data.notification.message)}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default NotificationViewM;

