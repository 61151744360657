import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {GetCustomerInfo} from "../../requests/get";
import {CustomInput, FileUploadIcon} from "../../helpers/Common";
import {UpdatePersonalInfo} from "../../requests/put";
import {MyTimer} from "../../helpers/helpers";
import {GetOtp} from "../../requests/post";

function PersonalEditM() {
    const navigate = useNavigate()
    const [userInfoValidation, setUserInfoValidation] = useState({
        emailCheck: false,
        emailHelperText: '',
        otpCheck: false,
        otpHelperText: '',
        sendCode: false,
        requestIsSend: false,
    })
    const [userInfo, setUserInfo] = useState({
        address: '',
        email: '',
        old_email:'',
        first_name: '',
        date_of_birth: '',
        last_name: '',
        otp: '',
        phone: '',
        passport_front_side: new File([""], ""),
        passport_back_side: new File([""], ""),
        passport_frontCheck: '',
        passport_backCheck: '',
    })
    const submitChanges = () =>{
        setUserInfoValidation({
            ...userInfoValidation,
            requestIsSend: true,
        })
        UpdatePersonalInfo(userInfo.first_name,
            userInfo.last_name,
            userInfo.phone,
            userInfo.email,
            userInfo.address,
            userInfo.otp,
            userInfo.passport_front_side,
            userInfo.passport_back_side,
            userInfo.date_of_birth,).then(()=>{
            navigate('/personal')
            window.location.reload()
        }).catch((err)=>{
            if(err.response.data.message.otp){
                setUserInfoValidation({
                    ...userInfoValidation,
                    otpCheck: true,
                    otpHelperText: err.response.data.message.otp[0],
                    requestIsSend: false,
                })
            }
            else{
                setUserInfoValidation({
                    ...userInfoValidation,
                    requestIsSend: false,
                })
            }
        })
    }
    useEffect(()=>{
        GetCustomerInfo().then((res)=>{
            setUserInfo({
                ...userInfo,
                address: res.data.address ? res.data.address : '',
                email: res.data.email,
                old_email: res.data.email,
                first_name: res.data.first_name,
                date_of_birth: res.data.date_of_birth,
                last_name: res.data.last_name,
                phone: res.data.phone,
                passport_frontCheck: res.data.passport_front_side,
                passport_backCheck: res.data.passport_back_side,
            })
        })
    },[])
    const time = new Date();
    const currentTimeLeftForCode = MyTimer(time)
    const handleResendCode = () =>{
        setUserInfoValidation({
            ...userInfoValidation,
            sendCode: true,
            otpHelperText: 'Код отправлен вам на почту'
        })
        const time = new Date();
        time.setSeconds(time.getSeconds() + 59);
        currentTimeLeftForCode.restart(time)
        GetOtp(userInfo.email).then(()=>{
        }).catch(()=>{
            setUserInfoValidation({
                ...userInfoValidation,
                sendCode: false,
                otpHelperText: 'Ошибка при отправке кода',
                otpCheck: true,
            })
        })
    }
    useEffect(()=>{
        if(currentTimeLeftForCode.seconds === 0){
            setUserInfoValidation({...userInfoValidation, sendCode: false})
        }
    }, [currentTimeLeftForCode.seconds])
    const handlePassportChange = (event: React.ChangeEvent<HTMLInputElement>, side: string ) =>{
        const files = event.target.files
        if(files !== null && files.length > 0){
            let fileReader = new FileReader()

            fileReader.onload = (event) =>{
                if(side === 'front' && event.target !== null && typeof event.target.result === 'string'){
                    setUserInfo({
                        ...userInfo,
                        passport_front_side: files[0],
                        passport_frontCheck: `${event.target.result}`
                    })
                }
                if(side === 'back' && event.target !== null && typeof event.target.result === 'string'){
                    setUserInfo({
                        ...userInfo,
                        passport_back_side: files[0],
                        passport_backCheck: `${event.target.result}`
                    })
                }
            }

            fileReader.readAsDataURL(files[0])
        }
    }
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>Личная страница</h1>
                </div>
                <form className='w-full flex flex-col justify-start items-center rounded-6px shadow-default bg-white'
                      onSubmit={(e)=>{
                          e.preventDefault()
                          submitChanges()
                      }}
                >
                    <div className='w-full flex flex-col justify-start items-start'>
                        <div className='passport__title w-full px-3 pt-5 pb-3'>
                            <span>Информация пользователя</span>
                        </div>
                        <div className='passport__body w-full px-3 pt-5 pb-7'>
                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder='Имя'
                                required={true}
                                label='Имя'
                                value={userInfo.first_name}
                                onChange={(event)=>{setUserInfo({
                                    ...userInfo,
                                    first_name: event.target.value,
                                })}}
                            />
                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder='Фамилия'
                                required={true}
                                label='Фамилия'
                                value={userInfo.last_name}
                                onChange={(event)=>{setUserInfo({
                                    ...userInfo,
                                    last_name: event.target.value,
                                })}}
                            />
                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder='Адрес'
                                required={false}
                                label='Адрес'
                                value={userInfo.address}
                                onChange={(event)=>{setUserInfo({
                                    ...userInfo,
                                    address: event.target.value,
                                })}}
                            />
                            <CustomInput
                                className='text-input'
                                type='date'
                                placeholder='Дата рождения'
                                label='Дата рождения'
                                value={userInfo.date_of_birth}
                                required={false}
                                onChange={(event)=>{setUserInfo({
                                    ...userInfo,
                                    date_of_birth: event.target.value,
                                })}}
                            />
                            <CustomInput
                                className='text-input'
                                type='email'
                                placeholder='Email'
                                required={true}
                                label='Email'
                                value={userInfo.email}
                                onChange={(event)=>{setUserInfo({
                                    ...userInfo,
                                    email: event.target.value,
                                })}}
                                helperText={userInfoValidation.emailHelperText}
                                error={userInfoValidation.emailCheck}
                            />
                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder='Номер телефона'
                                required={true}
                                label='Номер телефона'
                                value={userInfo.phone}
                                onChange={(event)=>{setUserInfo({
                                    ...userInfo,
                                    phone: '+' + event.target.value.replace(/\D/g, ''),
                                })}}
                            />
                            {userInfo.email !== userInfo.old_email &&
                                <CustomInput
                                    className='password-input otp-input'
                                    type='text'
                                    placeholder='Код'
                                    required={true}
                                    label='Код'
                                    value={userInfo.otp}
                                    onChange={(event)=>{setUserInfo({
                                        ...userInfo,
                                        otp: event.target.value,
                                    })}}
                                    helperText={userInfoValidation.otpHelperText}
                                    error={userInfoValidation.otpCheck}
                                    inputProps={
                                        <div className='otp_icon_button'>
                                            {!userInfoValidation.sendCode
                                                ? <p onClick={()=>handleResendCode()}>Отправить код</p>
                                                : <p>0:{currentTimeLeftForCode.seconds}</p>
                                            }
                                        </div>
                                    }
                                />
                            }
                        </div>
                    </div>
                    <div className='w-full flex flex-col justify-start items-start'>
                        <div className='passport__title w-full px-3 pt-5 pb-3'>
                            <span>Паспортные данные</span>
                        </div>
                        <div className='passport__body w-full px-3 pt-5 pb-7'>
                            <div className='passport-body__item'>
                                <span>Лицевая сторона</span>
                                <div>

                                    {userInfo.passport_frontCheck === ''
                                        ? <label htmlFor="frontImgUpload">
                                            <FileUploadIcon/>
                                            <p>Загрузить фотографию</p>
                                            <input id='frontImgUpload' type="file" accept='image/*' onChange={(event)=>{
                                                handlePassportChange(event, 'front')
                                            }} />
                                        </label>
                                        : <img src={userInfo.passport_frontCheck} alt="Лицевая сторона"/>
                                    }
                                </div>
                                {userInfo.passport_frontCheck !== '' &&
                                    <span onClick={()=>{
                                        setUserInfo({
                                            ...userInfo,
                                            passport_front_side: new File([""], ""),
                                            passport_frontCheck: ''
                                        })
                                    }}>
                                        Изменить фото
                                    </span>
                                }
                            </div>
                            <div className='passport-body__item'>
                                <span>Обратная сторона</span>
                                <div>
                                    {userInfo.passport_backCheck === ''
                                        ? <label htmlFor="backImgUpload">
                                            <FileUploadIcon/>
                                            <p>Загрузить фотографию</p>
                                            <input id='backImgUpload' type="file" accept='image/*' onChange={(event)=>{
                                                handlePassportChange(event, 'back')
                                            }}/>
                                        </label>
                                        : <img src={userInfo.passport_backCheck} alt="Обратная сторона"/>
                                    }
                                </div>
                                {userInfo.passport_backCheck !== '' &&
                                    <span onClick={()=>{
                                        setUserInfo({
                                            ...userInfo,
                                            passport_back_side: new File([""], ""),
                                            passport_backCheck: ''
                                        })
                                    }}>
                                        Изменить фото
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-3 mb-14'>
                        <button disabled={userInfoValidation.requestIsSend} className='personal-info-edit__button' type='submit'>
                            {userInfoValidation.requestIsSend
                                ? <div style={{width: '30px', height:'30px'}} className="loader"></div>
                                : <span>Сохранить</span>
                            }
                        </button>
                        <Link to='/personal' className='personal-password-edit__button'>
                            <span>Отменить</span>
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PersonalEditM;

