import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {GetOrders, GetOrderStatusButtons} from "../../requests/get";
import {
    CustomInput,
    CustomPagination,
    CustomTable,
    FilterSearchIcon,
    OrderDeleteIcon,
    OrderEditIcon, ShowAllStatusIcon
} from "../../helpers/Common";
import moment from "moment";
import {DeleteOrder} from "../../requests/delete";
import {DeleteSelectedOrders} from "../../requests/post";
import {Pagination} from "@mui/material";

function Home() {
    const navigate = useNavigate()
    const [ordersTitle, setOrdersTitle] = useState('Все посылки')
    const [filterValue, setFilterValue] = React.useState<any>({
        start_created_at: '',
        end_created_at: '',
        search: '',
        page: 1,
        count: 1,
        status__status: '',
        update_request: false,
    })
    const getOrderStatuses = GetOrderStatusButtons()
    const getOrders = GetOrders(filterValue)
    const mainTableColumns: any[] = [
        { field: 'id', headerName: 'ID'},
        { field: 'created_at', headerName: 'Дата', width: '110px'},
        { field: 'title', headerName: 'Наименование' },
        { field: 'quantity', headerName: 'Кол-во', width: '80px'},
        // { field: 'weight', headerName: 'Вес', width: '80px'},
        { field: 'tracking_number', headerName: 'Трек'},
        { field: 'status', headerName: 'Статус', width: '150px', renderCell: (params: any) => {
                return (
                    <div className="flex justify-start items-center gap-5">
                        <img src={params.status_icon} alt="icon" style={{width:'22px', height:'22px'}}/>
                        {params.status === 'inProcess' &&
                            <div className='page-table__icon-button' onClick={()=>{
                                navigate(`/orderEdit/${params.id}`)
                            }}>
                                <OrderEditIcon/>
                            </div>
                        }
                        {params.status === 'inProcess' &&
                            <div className='page-table__icon-button' onClick={()=>{
                                DeleteOrder(params.id).then(()=>{
                                    setFilterValue({
                                        ...filterValue,
                                        update_request:!filterValue.update_request
                                    })
                                })
                            }}>
                                <OrderDeleteIcon/>
                            </div>
                        }
                    </div>
                )
            }},
        { field: 'status_icon', headerName: '', hide: true,},
    ];
    const [selectedOrdersId, setSelectedOrdersId] = useState<any>([])
    const [mainTableData, setMainTableData] = useState<any>([])
    useEffect(()=>{
        if(!getOrders.loading && !getOrders.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: getOrders.result?.data.total_pages,
                page: getOrders.result?.data.current_page,
            }))
            const data = []
            for(let i=0; i<getOrders.result?.data.results.length;i++){
                data[i] = {
                    id: getOrders.result?.data.results[i].id,
                    created_at: (moment(getOrders.result?.data.results[i].created_at)).format('DD.MM.YYYY').toString(),
                    title: getOrders.result?.data.results[i].title,
                    quantity: (getOrders.result?.data.results[i].quantity),
                    // weight: getOrders.result?.data.results[i].weight,
                    tracking_number: getOrders.result?.data.results[i].tracking_number,
                    status: getOrders.result?.data.results[i].status.status,
                    status_icon: getOrders.result?.data.results[i].status.icon,
                }
            }
            setMainTableData(data)
        }
    },[getOrders.loading])
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-9'>
                    <Link to='/orderAdd' className='submit-button_blue w-fit h-12'>
                        Добавить посылку
                    </Link>
                </div>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>Статусы заказов</h1>
                </div>

                {/* статусы */}

                <div className='home-page__statuses mb-7'>
                    <div className={`status-button ${filterValue.status__status === '' ? 'status-button_selected' : ''}`}
                         onClick={()=>{
                             setFilterValue({
                                 ...filterValue,
                                 status__status: '',
                                 page: 1,
                             })
                             setOrdersTitle('Все посылки')
                         }}>
                        <div className='w-12 h-12 flex justify-center items-center'>
                            <ShowAllStatusIcon/>
                        </div>
                        <div>
                            <p>Все посылки</p>
                            <span>
                                {!getOrderStatuses.loading && !getOrderStatuses.error && getOrderStatuses.result?.data.map((result: any)=>result.count_of_orders).reduce((partialSum: any, a: any) => partialSum + a, 0)}
                            </span>
                        </div>
                    </div>
                    {getOrderStatuses.loading
                        ? <div className='p-4'>
                            <div className="loader"></div>
                        </div>
                        : getOrderStatuses.error
                            ? <div>Error</div>
                            : getOrderStatuses.result?.data.map((item:any) => (
                                <div className={`status-button ${filterValue.status__status === item.status ? 'status-button_selected' : ''}`}
                                     id={item.id}
                                     key={item.id}
                                     onClick={()=>{
                                         setFilterValue({
                                             ...filterValue,
                                             status__status: item.status,
                                             page: 1,
                                         })
                                         setOrdersTitle(item.name)
                                     }}>
                                    <div className='w-12 h-12 flex justify-center items-center'>
                                        <img src={item.icon} alt=""/>
                                    </div>
                                    <div>
                                        <p>{item.name}</p>
                                        <span>{item.count_of_orders}</span>
                                    </div>
                                </div>
                            ))
                    }
                </div>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>{ordersTitle}</h1>
                </div>

                {/* фильтры */}

                <div className='flex w-full flex-row justify-between items-center mb-6'>
                    <div className='flex w-fit flex-row justify-start items-center gap-5'>
                        <CustomInput
                            className='filter-input'
                            type='date'
                            placeholder='Начало даты'
                            value={filterValue.start_created_at}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                start_created_at: event.target.value,
                                page: 1,
                            })}}
                        />
                        <CustomInput
                            className='filter-input'
                            type='date'
                            placeholder='Конец даты'
                            value={filterValue.end_created_at}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                end_created_at: event.target.value,
                                page: 1,
                            })}}
                        />
                    </div>
                    <div className='flex w-fit flex-row justify-start items-center'>
                        <CustomInput
                            className='filter-input_search'
                            type='text'
                            placeholder='Введите номер трека или посылки'
                            value={filterValue.search}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                search: event.target.value,
                                page: 1,
                            })}}
                            inputProps={
                                <div className='text-input_icon_button'>
                                    <FilterSearchIcon/>
                                </div>
                            }
                        />
                    </div>
                </div>

                {/* таблица */}
                <CustomTable
                    columns={mainTableColumns}
                    rows={mainTableData}
                    checkboxSelection={true}
                    loading={getOrders.loading}
                    onSelectAll={(params: any) => params.status === 'inProcess'}
                    onSelectionModelChange={(selectionModel: [])=>setSelectedOrdersId(selectionModel)}
                    isRowSelectable={(params: any) => params.status !== 'inProcess'}
                    onRowDoubleClick={(params: any)=>{ navigate(`orderView/${params.id}`)}}
                    footer={
                        <div className="page-table__footer flex flex-col justify-start items-center p-2.5">
                            <div className='w-full flex justify-between items-center mt-2.5 mr-2.5'>
                                <div>

                                </div>
                                <div className='flex justify-center items-center gap-5'>
                                    {selectedOrdersId.length > 0 &&
                                        <span className='selected-orders-count'>Выбрано {selectedOrdersId.length} заказа</span>
                                    }
                                    <button disabled={selectedOrdersId.length === 0}
                                            className='submit-button_white submit-button_white_small h-7'
                                            onClick={()=>{
                                                DeleteSelectedOrders(selectedOrdersId).then(()=>{
                                                    setFilterValue({
                                                        ...filterValue,
                                                        update_request: !filterValue.update_request,
                                                    })
                                                    setSelectedOrdersId([])
                                                })
                                            }}
                                    >
                                        Удалить</button>
                                </div>
                            </div>
                            <Pagination
                                color="primary"
                                count={filterValue.count}
                                page={filterValue.page}
                                onChange={(event,value)=>{
                                    setFilterValue({
                                        ...filterValue,
                                        page: value,
                                    })
                                }}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
}

export default Home;

