import {useAsync} from "react-async-hook";
import url from "../helpers/Url";
import {$axios} from "../http";
import {CreateCleanSearchParams} from "../helpers/helpers";
import {createSearchParams} from "react-router-dom";

function GetCurrencies(){
    return useAsync(async () => {
         return await $axios.get(url('currencies'))
    }, [])
}
function GetNotifications(searchParamsObj?: any){
    return useAsync(async () => {
         return await $axios.get(url('notifications') + CreateCleanSearchParams(searchParamsObj))
    }, [`?${createSearchParams(searchParamsObj)}`])
}
async function GetCustomerInfo() {
    return await $axios.get(url('customer_info'))
}
function GetOrderStatusButtons(){
    return useAsync(async () => {
         return await $axios.get(url('statuses'))
    }, [])
}
function GetOrders(searchParamsObj: any){
    return useAsync(async () => {
         return await $axios.get(url('orders') + CreateCleanSearchParams(searchParamsObj));
    }, [`?${createSearchParams(searchParamsObj)}`])
}
function GetOrder(id: string | undefined){
    return useAsync(async () => {
         return await $axios.get(url('orders') +`${id}`);
    }, [id])
}
function GetNotification(id: string | undefined){
    return useAsync(async () => {
         return await $axios.get(url('notifications') +`${id}`);
    }, [id])
}
function GetAddress(){
    return useAsync(async () => {
         return await $axios.get(url('addresses'));
    }, [])
}
export {GetAddress, GetNotification, GetOrder, GetOrders, GetOrderStatusButtons, GetNotifications, GetCustomerInfo, GetCurrencies}