import React from "react";
import {useTimer} from "react-timer-hook";
import {createSearchParams, Navigate} from "react-router-dom";
import {getCookie} from 'typescript-cookie'

function CreateCleanSearchParams(params: any){
    const newSearchObj = {...params}
    const keys = Object.keys(newSearchObj)
    for(let i=0;i < keys.length;i++){
        if(newSearchObj[keys[i]] === ''){
            delete newSearchObj[keys[i]]
        }
    }
    const emptyOrder = !Object.keys(newSearchObj).length;
    return emptyOrder ? '' : `?${createSearchParams(newSearchObj)}`
}
const MyTimer = (expiryTimestamp: Date) => {
    const {
        seconds,
        restart,
    } = useTimer({expiryTimestamp});
    return ({seconds, restart});
}
const RequireAuth = ({children}: {children: JSX.Element}) => {
    let access_token = getCookie('goldMark_user_access_token')
    let refresh_token = getCookie('goldMark_user_refresh_token')
    if (typeof access_token !== 'undefined' && typeof refresh_token !== 'undefined') {
        return children
    }
    else{
        return <Navigate to="auth" replace />
    }
}
const IfAuthed = ({children}: {children: JSX.Element}) => {
    let access_token = getCookie('goldMark_user_access_token')
    let refresh_token = getCookie('goldMark_user_refresh_token')
    if (typeof access_token !== 'undefined' && typeof refresh_token !== 'undefined') {
        return <Navigate to="/" replace />
    }
    else{
        return children
    }
}

function StringAvatar(name: string) {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
}
export { StringAvatar, CreateCleanSearchParams, IfAuthed, MyTimer, RequireAuth};

