import React from 'react';
import {GetAddress} from "../../requests/get";
import {CopyIcon, CustomInput} from "../../helpers/Common";
import {useSelector} from "react-redux";

function AddressesM() {
    const user = useSelector((state: any) => state.user)
    const address = GetAddress()

    const copy = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>{
        const targetToCopy: any = event.currentTarget.previousElementSibling
        targetToCopy.select()
        document.execCommand("copy");
    }
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>Адрес в Китае</h1>
                </div>
                {address.loading
                    ?
                    <div className='w-full flex justify-center items-center'>
                        <div className="loader"></div>
                    </div>
                    :
                    <div className='address__container w-full flex flex-col justify-start items-center'>
                        <div className='address__header w-full px-3 py-3 flex flex-col justify-start items-start'>
                            <div className='flex justify-start items-center gap-3 mb-3'>
                                <p className='address-client-code__title'>Клиентский код:</p>
                                <span className='address-client-code__text'>{address.result?.data.prefix} {user.code}</span>
                            </div>
                            <div className='address-tariff flex flex-col justify-start items-start gap-3'>
                                <div><p>Доставка:</p><span>{address.result?.data.delivery_period} рабочих дней</span></div>
                                <div><p>Тариф:</p><span>{address.result?.data.tariff} $ </span></div>
                            </div>
                        </div>
                        <div className='address__body w-full flex flex-col justify-start items-start px-3 py-3'>
                            <CustomInput
                                className='text-input text-input_with-props'
                                type='text'
                                placeholder='Получатель:'
                                label='Получатель:'
                                readOnly={true}
                                value={`${user.first_name} ${user.last_name}`}
                                inputProps={
                                    <button className='text-input_icon_button' onClick={copy}>
                                        <CopyIcon/>
                                    </button>
                                }
                            />
                            <CustomInput
                                className='text-input text-input_with-props'
                                type='text'
                                placeholder='Address 1:'
                                label='Address 1:'
                                readOnly={true}
                                value={address.result?.data.address1}
                                inputProps={
                                    <button className='text-input_icon_button' onClick={copy}>
                                        <CopyIcon/>
                                    </button>
                                }
                            />
                            <CustomInput
                                className='text-input text-input_with-props'
                                type='text'
                                placeholder='Address 2:'
                                label='Address 2:'
                                readOnly={true}
                                value={address.result?.data.address2}
                                inputProps={
                                    <button className='text-input_icon_button' onClick={copy}>
                                        <CopyIcon/>
                                    </button>
                                }
                            />
                            <CustomInput
                                className='text-input text-input_with-props'
                                type='text'
                                placeholder='Zip Code:'
                                label='Zip Code:'
                                readOnly={true}
                                value={address.result?.data.zip ? address.result?.data.zip : ''}
                                inputProps={
                                    <button className='text-input_icon_button' onClick={copy}>
                                        <CopyIcon/>
                                    </button>
                                }
                            />
                            <CustomInput
                                className='text-input text-input_with-props'
                                type='text'
                                placeholder='Country:'
                                label='Country:'
                                readOnly={true}
                                value={address.result?.data.country}
                                inputProps={
                                    <button className='text-input_icon_button' onClick={copy}>
                                        <CopyIcon/>
                                    </button>
                                }
                            />
                            <CustomInput
                                className='text-input text-input_with-props'
                                type='text'
                                placeholder='City:'
                                label='City:'
                                readOnly={true}
                                value={address.result?.data.city}
                                inputProps={
                                    <button className='text-input_icon_button' onClick={copy}>
                                        <CopyIcon/>
                                    </button>
                                }
                            />
                            <CustomInput
                                className='text-input text-input_with-props'
                                type='text'
                                placeholder='Phone Number:'
                                label='Phone Number:'
                                readOnly={true}
                                value={address.result?.data.phone}
                                inputProps={
                                    <button className='text-input_icon_button' onClick={copy}>
                                        <CopyIcon/>
                                    </button>
                                }
                            />
                            <div className='address-body__note w-full p-5'>
                                <h1>Важно знать:</h1>
                                <p>
                                    *Просим внимательно заполнить адрес склада в Китае.
                                    <br/>*Компания несёт ответственность с момента получения Вашей посылки/груза на складе.
                                    <br/>*На посылках/грузах всегда указывайте свой клиентский код (G-...). Данный код является маркировкой вашего заказа.
                                    <br/>*Всегда сохраняйте трек номеры своих посылок. Трек номер -это код доставки внутри Китая.
                                </p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default AddressesM;

