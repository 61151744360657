import React, {useEffect, useState} from 'react';
import headerLogoImg from '../../assets/images/Artboard 7 1.svg'
import {
    RollBackIcon,
    SideBarAddressIcon,
    SideBarBellIcon, SideBarExitIcon,
    SideBarHomeIcon, SideBarOpenIcon,
    SideBarPhoneIcon,
    SideBarUserIcon
} from "../../helpers/Common";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {removeCookie} from "typescript-cookie";
import {useDispatch, useSelector} from "react-redux";
import {GetCustomerInfo, GetNotifications} from "../../requests/get";
import {StringAvatar} from "../../helpers/helpers";

interface userI {
    code: number | null | undefined,
    address: string | null | undefined,
    first_name: string | null | undefined,
    last_name: string | null | undefined,
    date_of_birth: string | null | undefined,
    email: string | null | undefined,
    phone: string | null | undefined,
    is_verified: boolean | undefined,
    passport_front_side: File | null | undefined,
    passport_back_side: File | null | undefined,
    balance: string | undefined,
}
function HeaderM() {
    const [sideBar, setSideBar] = useState(false)
    const navigate = useNavigate()
    const user = useSelector((state: any) => state.user)
    const location = useLocation()
    const dispatch = useDispatch()
    const setUser = ({balance, date_of_birth, code, is_verified, address, first_name, last_name, email, phone, passport_back_side, passport_front_side}:userI ) =>{
        dispatch({
            type:"UPDATE_DATA",
            payload:{
                code: (typeof code === 'undefined' ? '' : `${code}` ),
                address: (typeof address === 'undefined' ? '' : address ),
                first_name: (typeof first_name === 'undefined' ? '' : first_name ),
                last_name: (typeof last_name === 'undefined' ? '' : last_name ),
                date_of_birth: (typeof date_of_birth === 'undefined' ? '' : date_of_birth ),
                email: (typeof email === 'undefined' ? '' : email ),
                phone: (typeof phone === 'undefined' ? '' : phone ),
                is_verified: (typeof is_verified === 'undefined' ? false : is_verified ),
                passport_back_side: (typeof passport_back_side === 'undefined' ? '' : passport_back_side ),
                passport_front_side: (typeof passport_front_side === 'undefined' ? '' : passport_front_side ),
                balance: (typeof balance === 'undefined' ? '' : balance ),
            }
        })
    }
    useEffect(()=>{
        GetCustomerInfo().then((res)=>{
            setUser({...res.data})
        })
    },[])

    const notifications = GetNotifications()
    return (
        <div className='header' id='header'>
            <div className="header__mainBar">
                <div className='header-sideBar__openButton' onClick={()=>{setSideBar(true)}}>
                    <SideBarOpenIcon/>
                </div>
                <div className='flex justify-between items-center gap-7'>
                    <Link to='/notifications'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0196 2.91016C8.7096 2.91016 6.0196 5.60016 6.0196 8.91016V11.8002C6.0196 12.4102 5.7596 13.3402 5.4496 13.8602L4.2996 15.7702C3.5896 16.9502 4.0796 18.2602 5.3796 18.7002C9.6896 20.1402 14.3396 20.1402 18.6496 18.7002C19.8596 18.3002 20.3896 16.8702 19.7296 15.7702L18.5796 13.8602C18.2796 13.3402 18.0196 12.4102 18.0196 11.8002V8.91016C18.0196 5.61016 15.3196 2.91016 12.0196 2.91016Z" stroke="#000032" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
                            <path d="M13.8699 3.20043C13.5599 3.11043 13.2399 3.04043 12.9099 3.00043C11.9499 2.88043 11.0299 2.95043 10.1699 3.20043C10.4599 2.46043 11.1799 1.94043 12.0199 1.94043C12.8599 1.94043 13.5799 2.46043 13.8699 3.20043Z" stroke="#000032" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M15.0195 19.0596C15.0195 20.7096 13.6695 22.0596 12.0195 22.0596C11.1995 22.0596 10.4395 21.7196 9.89953 21.1796C9.35953 20.6396 9.01953 19.8796 9.01953 19.0596" stroke="#000032" strokeWidth="1.5" strokeMiterlimit="10"/>
                            {notifications.loading
                                ? ''
                                : notifications.error
                                    ? ''
                                    : notifications.result?.data.results.filter((notification: any) => notification.read === false).length > 0
                                        ? <circle cx="19.5" cy="6.5" r="4.5" fill="#DB3737"/>
                                        : ''
                            }
                        </svg>
                    </Link>
                    <Link to='/personal'>
                        <div className='header__avatar w-50px h-50px flex justify-center items-center bg-green rounded-full'>
                            {StringAvatar(`${user.first_name} ${user.last_name}`)}
                        </div>
                    </Link>
                </div>
            </div>
            <div className={`header__sideBar ${sideBar ? 'header__sideBar_opened' : 'header__sideBar_closed'} flex flex-col justify-start items-center pt-4 pb-32`}>
                {sideBar &&
                    <div className='header-sideBar__closeButton' onClick={()=>{setSideBar(false)}}>
                        <RollBackIcon/>
                    </div>
                }
                <Link to='/'>
                    <img src={headerLogoImg} alt="logo" className="header-sideBar__logo mb-12"/>
                </Link>
                <div className="header-sideBar__links">
                    <div className='w-full h-full overflow-y-auto'>
                        <Link to='/' onClick={()=>{setSideBar(false)}} className={location.pathname === '/' ? "header-sideBar__link header-sideBar__link_active": 'header-sideBar__link'}>
                            <SideBarHomeIcon/><span>Главная</span>
                        </Link>
                        <Link to='/personal' onClick={()=>{setSideBar(false)}} className={location.pathname === '/personal' || location.pathname === '/personalEdit' ? "header-sideBar__link header-sideBar__link_active": 'header-sideBar__link'}>
                            <SideBarUserIcon/><span>Личная страница</span>
                        </Link>
                        <Link to='/addresses' onClick={()=>{setSideBar(false)}} className={location.pathname === '/addresses' ? "header-sideBar__link header-sideBar__link_active": 'header-sideBar__link'}>
                            <SideBarAddressIcon/><span>Адреса</span>
                        </Link>
                        <Link to='/contacts' onClick={()=>{setSideBar(false)}} className={location.pathname === '/contacts' ? "header-sideBar__link header-sideBar__link_active": 'header-sideBar__link'}>
                            <SideBarPhoneIcon/><span>Контакты</span>
                        </Link>
                        <Link to='/notifications' onClick={()=>{setSideBar(false)}} className={location.pathname === '/notifications' ? "header-sideBar__link header-sideBar__link_active": 'header-sideBar__link'}>
                            <SideBarBellIcon/><span>Уведомления</span>
                        </Link>
                    </div>
                    <div className="header-sideBar__link header-sideBar__link_exit" onClick={()=>{
                        setSideBar(false)
                        removeCookie('goldMark_user_access_token')
                        removeCookie('goldMark_user_refresh_token')
                        navigate('/auth')
                    }}>
                        <SideBarExitIcon/><span>Выход</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderM;