import React, {useEffect, useState} from 'react';
import {CustomInput} from "../../helpers/Common";
import {useNavigate, useParams} from "react-router-dom";
import {GetOrder} from "../../requests/get";
import {UpdateOrder} from "../../requests/put";

function OrderEditM() {
    let {id} = useParams()
    const navigate = useNavigate()
    const order = GetOrder(id)
    const [orderValues, setOrderValues] = useState<any>({
        tracking_number: '',
        title: '',
        quantity: '',
        status: '',
    })
    const [orderValidation, setOrderValidation] = useState<any>({
        tracking_numberCheck: false,
        tracking_numberHelperText: '',
        requestIsSent: false,
    })
    const handleSubmit = (event: React.FormEvent) =>{
        event.preventDefault()
        setOrderValidation({
            ...orderValidation,
            requestIsSent: true,
        })
        UpdateOrder(id, orderValues).then(()=>{
            navigate(`/orderList/${orderValues.status}`)
        }).catch((err)=>{
            if(err.response.data.tracking_number){
                setOrderValidation({
                    ...orderValidation,
                    tracking_numberCheck: true,
                    tracking_numberHelperText: err.response.data.tracking_number[0],
                    requestIsSent: false,
                })
            }
        })
    }
    useEffect(()=>{
        if(!order.loading && !order.error){
            setOrderValues({
                tracking_number: order.result?.data.tracking_number,
                title: order.result?.data.title,
                quantity: order.result?.data.quantity,
                status: order.result?.data.status.status,
            })
        }
    },[order.loading])
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>Редактирование посылки</h1>
                </div>
                <form onSubmit={handleSubmit} className='w-full flex flex-col justify-start items-center'>
                    <CustomInput
                        className='text-input order-add__input'
                        type='text'
                        placeholder='Трек номер'
                        required={true}
                        label='Трек номер'
                        value={orderValues.tracking_number}
                        onChange={(event)=>{setOrderValues({
                            ...orderValues,
                            tracking_number: event.target.value,
                        })}}
                        helperText={orderValidation.tracking_numberHelperText}
                        error={orderValidation.tracking_numberCheck}
                    />
                    <CustomInput
                        className='text-input order-add__input'
                        type='text'
                        placeholder='Наименование'
                        required={true}
                        label='Наименование'
                        value={orderValues.title}
                        onChange={(event)=>{setOrderValues({
                            ...orderValues,
                            title: event.target.value,
                        })}}
                    />
                    <CustomInput
                        className='text-input order-add__input'
                        type='number'
                        placeholder='Количество'
                        required={true}
                        label='Количество'
                        value={orderValues.quantity}
                        onChange={(event)=>{setOrderValues({
                            ...orderValues,
                            quantity: event.target.value,
                        })}}
                    />
                    <div className='flex w-full flex-row justify-end items-center gap-7 mb-9 mt-2'>
                        <button type='submit' className='submit-button_blue w-full h-12'>
                            {orderValidation.requestIsSent
                                ? <div className="loader"></div>
                                : 'Сохранить'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default OrderEditM;

