import {$axios} from "../http";
import url from "../helpers/Url";
async function DeleteNotifications(id: string) {
    return await $axios.delete(url('notifications') + `${id}/`)
}
async function DeleteOrder(id: string) {
    return await $axios.delete(url('orders') + `${id}/`)
}
async function DeleteNotification(id: string) {
    return await $axios.delete(url('notifications') + `${id}/`)
}
export {DeleteOrder,DeleteNotifications, DeleteNotification}