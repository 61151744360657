export default function Url(props: any) {
    const mainUrl = 'https://api.goldmark.kg/api'
    const url = {
        apiUrl: mainUrl,
        auth_token_create: mainUrl + '/auth/token/',
        token_refresh: mainUrl + '/auth/token/refresh/',
        otp: mainUrl + '/otp/',
        register: mainUrl + '/register/',
        recovery: mainUrl + '/password/recovery/',
        customer_info: mainUrl + '/personal/info/',
        customer_info_update: mainUrl + '/personal/info/update/',
        currencies: mainUrl + '/currencies/',
        notifications: mainUrl + '/notifications/',
        addresses: mainUrl + '/addresses/',
        statuses: mainUrl + '/statuses/',
        orders: mainUrl + '/orders/',
        orders_bulk_delete: mainUrl + '/orders/bulk/delete/',
        notifications_bulk_delete: mainUrl + '/notifications/bulk/update/delete/',
        passwordChange: mainUrl + '/password/change/'
    }
    return url[props as keyof typeof url]
}