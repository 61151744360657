import React, {useState} from 'react';
import {StringAvatar} from "../../helpers/helpers";
import {useSelector} from "react-redux";
import {
    CustomInput, EmptyImg,
    PasswordHiddenIcon,
    PasswordVisibleIcon,
    PersonalEditIcon,
    PersonalPasswordIcon, PhotoAddIcon
} from "../../helpers/Common";
import {PasswordChange} from "../../requests/post";
import {Link} from "react-router-dom";

function PersonalM() {
    const user = useSelector((state: any) => state.user)
    const [passwordValidation, setPasswordValidation] = useState({
        modal_opened: false,
        old_passwordCheck: false,
        new_passwordCheck: false,
        old_passwordHelperText: '',
        new_passwordHelperText: '',
        passwordVisible: false,
        requestIsSend: false,
    })
    const [passwordValues, setPasswordValues] = useState({
        old_password: '',
        new_password: '',
        repeat_new_password: '',
    })
    const submitPasswordChanges = () =>{
        setPasswordValidation({
            ...passwordValidation,
            requestIsSend: true,
        })
        PasswordChange(passwordValues.old_password, passwordValues.new_password, passwordValues.repeat_new_password).then(()=>{
            window.location.reload()
        }).catch((err)=>{
            if(err.response.data.old_password){
                setPasswordValidation({
                    ...passwordValidation,
                    old_passwordCheck: true,
                    old_passwordHelperText: err.response.data.old_password[0],
                    requestIsSend: false,
                })
            }
            if(err.response.data.password){
                setPasswordValidation({
                    ...passwordValidation,
                    new_passwordCheck: true,
                    new_passwordHelperText: err.response.data.password[0],
                    requestIsSend: false,
                })
            }
        })
    }
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>Личная страница</h1>
                </div>
                <div className='w-full flex flex-col justify-start items-center rounded-6px shadow-default bg-white'>
                    <div className='passport__title w-full px-3 pt-5 pb-3 mb-3'>
                        <span>Информация о пользователе</span>
                    </div>
                    <div className='w-full p-3 flex justify-start items-start relative'>
                        <div className='personal__avatar flex justify-center items-center bg-green rounded-20px mr-3'>
                            {StringAvatar(`${user.first_name} ${user.last_name}`)}
                        </div>
                        <div className='personal__info flex flex-col justify-start items-start'>
                            <div>
                                <p>{user.first_name} {user.last_name}</p>
                                <span>ID {user.code}</span>
                            </div>
                            <div>
                                <span style={user.is_verified ? {color: '#19BD81'} : {color: '#DB3737'}}>{user.is_verified ? 'Аккаунт подтвержден' : 'Аккаунт не подтвержден'}</span>
                            </div>
                        </div>
                        <div className='personal__editButtons absolute flex gap-3'>
                            <button className='personal-password-edit__button'
                                    onClick={()=>{setPasswordValidation({...passwordValidation, modal_opened:true, })}}
                            >
                                <PersonalPasswordIcon/>
                            </button>
                            <Link to='/personalEdit' className='personal-info-edit__button'>
                                <PersonalEditIcon/>
                            </Link>
                        </div>
                    </div>
                    <div className='w-full px-3 personal__info_mobile flex flex-col justify-start items-start'>
                        <div>
                            <p>Почта:</p>
                            <span>{user.email}</span>
                        </div>
                        <div>
                            <p>Номер:</p>
                            <span>{user.phone}</span>
                        </div>
                        <div>
                            <p>Адрес:</p>
                            <span>{user.address}</span>
                        </div>
                        <div>
                            <p>Баланс:</p>
                            <span >{user.balance}c</span>
                        </div>
                    </div>
                    <div className='w-full flex flex-col justify-start items-start'>
                        <div className='passport__title w-full px-3 pt-5 pb-3'>
                            <span>Паспортные данные</span>
                        </div>
                        <div className='passport__body w-full px-3 pt-5 pb-3'>
                            <div className='passport-body__item'>
                                <span>Лицевая сторона</span>
                                <div>
                                    {user.passport_front_side
                                        ? <img src={user.passport_front_side} alt="Лицевая сторона"/>
                                        : <EmptyImg/>
                                    }
                                </div>
                            </div>
                            <div className='passport-body__item'>
                                <span>Обратная сторона</span>
                                <div>
                                    {user.passport_back_side
                                        ? <img src={user.passport_back_side} alt="Обратная сторона"/>
                                        : <EmptyImg/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {passwordValidation.modal_opened &&
                    <div onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setPasswordValidation({...passwordValidation, modal_opened: false,})
                    }}
                         className='personal-password-edit__modal fixed top-0 left-0 z-999 w-full h-full flex justify-center items-center bg-white-opacity p-4'
                    >
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            submitPasswordChanges()
                        }}
                              onClick={(e)=>{
                                  e.stopPropagation()
                              }}
                             style={{maxWidth: '620px', width: '100%'}}
                             className='relative flex flex-col bg-white justify-start items-center px-3 py-8 rounded-10px shadow-default'>
                            <div onClick={(event) => {
                                setPasswordValidation({...passwordValidation, modal_opened: false,})
                            }}
                                 className='absolute right-6 top-8 rotate-45 cursor-pointer rounded-full flex justify-center items-center hover:bg-icon-button-hover'
                                 style={{width: '30px', height: '30px'}}>
                                <PhotoAddIcon/>
                            </div>
                            <h1 className='mb-7'>Изменение пароля</h1>
                            <CustomInput
                                className='text-input text-input_with-props'
                                type={passwordValidation.passwordVisible ? 'text' : 'password'}
                                placeholder='Старый пароль'
                                label='Старый пароль'
                                value={passwordValues.old_password}
                                required={true}
                                onChange={(event) => {
                                    setPasswordValues({
                                        ...passwordValues,
                                        old_password: event.target.value,
                                    })
                                }}
                                helperText={passwordValidation.old_passwordHelperText}
                                error={passwordValidation.old_passwordCheck}
                                inputProps={
                                    <div className='text-input_icon_button' onClick={() => {
                                        setPasswordValidation({
                                            ...passwordValidation,
                                            passwordVisible: !passwordValidation.passwordVisible,
                                        })
                                    }}>
                                        {passwordValidation.passwordVisible ? <PasswordHiddenIcon/> :
                                            <PasswordVisibleIcon/>}
                                    </div>
                                }
                            />
                            <CustomInput
                                className='text-input text-input_with-props'
                                type={passwordValidation.passwordVisible ? 'text' : 'password'}
                                placeholder='Новый пароль'
                                label='Новый пароль'
                                value={passwordValues.new_password}
                                required={true}
                                onChange={(event) => {
                                    setPasswordValues({
                                        ...passwordValues,
                                        new_password: event.target.value,
                                    })
                                }}
                                helperText={passwordValidation.new_passwordHelperText}
                                error={passwordValidation.new_passwordCheck}
                                inputProps={
                                    <div className='text-input_icon_button' onClick={() => {
                                        setPasswordValidation({
                                            ...passwordValidation,
                                            passwordVisible: !passwordValidation.passwordVisible,
                                        })
                                    }}>
                                        {passwordValidation.passwordVisible ? <PasswordHiddenIcon/> :
                                            <PasswordVisibleIcon/>}
                                    </div>
                                }
                            />
                            <CustomInput
                                className='text-input text-input_with-props'
                                type={passwordValidation.passwordVisible ? 'text' : 'password'}
                                placeholder='Повторите пароль'
                                label='Повторите пароль'
                                value={passwordValues.repeat_new_password}
                                required={true}
                                onChange={(event) => {
                                    setPasswordValues({
                                        ...passwordValues,
                                        repeat_new_password: event.target.value,
                                    })
                                }}
                                helperText={passwordValues.new_password !== passwordValues.repeat_new_password ? 'Пароли не соответствуют!' : ''}
                                error={passwordValues.new_password !== passwordValues.repeat_new_password}
                                inputProps={
                                    <div className='text-input_icon_button' onClick={() => {
                                        setPasswordValidation({
                                            ...passwordValidation,
                                            passwordVisible: !passwordValidation.passwordVisible,
                                        })
                                    }}>
                                        {passwordValidation.passwordVisible ? <PasswordHiddenIcon/> :
                                            <PasswordVisibleIcon/>}
                                    </div>
                                }
                            />
                            <button type='submit' disabled={passwordValidation.requestIsSend} className='personal-info-edit__button'>
                                {passwordValidation.requestIsSend
                                    ? <div style={{width: '30px', height:'30px'}} className="loader"></div>
                                    : <span>Редактировать</span>
                                }
                            </button>
                        </form>
                    </div>
                }
            </div>
        </div>
    );
}

export default PersonalM;

