import url from "../helpers/Url";
import {$axios} from "../http";

async function UpdateOrder(id: string | undefined, data: any) {
    return await $axios.put(url('orders') + `${id}/`,{...data})
}
async function UpdatePersonalInfo(first_name: string,
                                  last_name: string,
                                  phone: string,
                                  email: string,
                                  address: string,
                                  otp: string,
                                  frontSideImg: File | null | undefined,
                                  backSideImg: File | null | undefined,
                                  date_of_birth: string,) {
    let form_data = new FormData()
    const data: any = {
        passport_front_side: frontSideImg,
        passport_back_side: backSideImg,
        phone: phone,
        address: address,
        first_name: first_name,
        last_name: last_name,
        email: email,
        otp: otp,
        date_of_birth: date_of_birth,
    }
    if(data.passport_back_side.name === ''){
        delete data.passport_back_side
    }
    if(data.passport_front_side.name === ''){
        delete data.passport_front_side
    }
    for ( let key in data ) {
        form_data.append(key, data[key]);
    }
    return await $axios.put(url('customer_info_update'),form_data)
}

export {UpdateOrder, UpdatePersonalInfo}